@import '~reset-css/reset.css';
@import '~src/styles/variables';

:root {
  font-size: $font-size-desktop;
  font-family: $font-family-1;
  line-height: 1.5em;

  @media screen and (max-width: 768px) {
    font-size: $font-size-mobile;
    font-family: $font-family-1;
    line-height: 1.5em;
  }
}

html {
  overflow-x: hidden;
}

body {
  background-color: #fafafa;
}
