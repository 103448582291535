// Core Component
$layout-page-width: var(--page-width);
$layout-viewport-height: var(--viewport-height);

// Grid Mobile
$grid-columns-mobile: 4;
$grid-gutter-mobile: 16px;
$container-width-mobile: 375px;
$container-padding-mobile: 12px;

// Grid Desktop
$grid-columns-desktop: 12;
$grid-gutter-desktop: 40px;
$container-width-desktop: 1240px;
$container-padding-desktop: 12px;

// Boxshadow
$boxshadow-color: #000000 !default;
$boxshadow-weak: 0.06 !default;
$boxshadow-medium: 0.12 !default;
$boxshadow-strong: 0.16 !default;

// Typography
$font-size-desktop: 20px;
$font-size-mobile: 16px;
$font-family-1: "Lato", sans-serif;
$font-family-2: "Roboto", sans-serif;
$line-height: 4px;

// Spacing
$spacing: 8px;

// Z-index
$zindex-negative: -1;
$zindex-0: 0;
$zindex-1: 10;
$zindex-2: 20;
$zindex-3: 30;
$zindex-4: 40;
$zindex-5: 50;
$zindex-6: 60;
$zindex-7: 70;
$zindex-8: 80;
$zindex-9: 90; // Opened menus
$zindex-10: 100; // Transition Element

// Footer
$footer-height: 75vh;

//
// Colors
//

// Color Profiles
//

// Neutral
$white: #ffffff; // rgba(255, 255, 255, 1)
$light-gray: #fafafa; // rgba(250, 250, 250, 1)
$gray: #e6e6e6; // rgba(230, 230, 230, 1)
$dark-gray: #333333; // rgba(51, 51, 51, 1)
$black: #0d0d0c; // rgba(13, 13, 12, 1)

// Main
$main-lighter: #fdebe2; // rgba(253, 235, 226, 1)
$main-light: #fbb8b2; // rgba(251, 184, 178, 1)
$main: #f5874f; // rgba(245, 135, 79, 1)
$main-dark: #d9530f; // rgba(217, 83, 15, 1)
$main-darker: #a02b2d; // rgba(160, 43, 45, 1)

// Fito
$fito-lighter: #d4f7df; // rgba(212, 247, 223, 1)
$fito-light: #8aeaa8; // rgba(138, 234, 168, 1)
$fito: #096125; // rgba(9, 97, 37, 1)
$fito-dark: #07481b; // rgba(7, 72, 27, 1)
$fito-darker: #052e11; // rgba(5, 46, 17, 1)

// Spirit
$spirit-lighter: #ebd6f2; // rgba(235, 214, 242, 1)
$spirit-light: #c27ddb; // rgba(194, 125, 219, 1)
$spirit: #8719ae; // rgba(135, 25, 174, 1)
$spirit-dark: #650e85; // rgba(101, 14, 133, 1)
$spirit-darker: #50066b; // rgba(80, 6, 107, 1)

// Prosper
$prosper-lighter: #ffeec2; // rgba(255, 238, 194, 1)
$prosper-light: #fade93; // rgba(250, 222, 147, 1)
$prosper: #f6bf31; // rgba(246, 191, 49, 1)
$prosper-dark: #db7416; // rgba(219, 116, 22, 1)
$prosper-darker: #a26201; // rgba(162, 98, 1, 1)

// Containers

$container-surface: $white;
$container-background: $light-gray;
$container-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%);

$main-container-color: radial-gradient(
  100% 100% at 0% 0%,
  rgba($main-lighter, 0.5) 0%,
  rgba($white, 0) 100%
),
radial-gradient(
  100% 100% at 100% 100%,
  rgba($main-light, 0.5) 0%,
  rgba($white, 0) 100%
),
$white;

$fito-container-color: radial-gradient(
    100% 100% at 0% 0%,
    rgba($fito-lighter, 0.5) 0%,
    rgba($white, 0) 100%
  ),
  radial-gradient(
    100% 100% at 100% 100%,
    rgba($fito-light, 0.5) 0%,
    rgba($white, 0) 100%
  ),
  $white;

$spirit-container-color: radial-gradient(
    100% 100% at 0% 0%,
    rgba($spirit-lighter, 0.5) 0%,
    rgba($white, 0) 100%
  ),
  radial-gradient(
    100% 100% at 100% 100%,
    rgba($spirit-light, 0.5) 0%,
    rgba($white, 0) 100%
  ),
  $white;

$prosper-container-color: radial-gradient(
    100% 100% at 0% 0%,
    rgba($prosper-lighter, 0.5) 0%,
    rgba($white, 0) 100%
  ),
  radial-gradient(
    100% 100% at 100% 100%,
    rgba($prosper-light, 0.5) 0%,
    rgba($white, 0) 100%
  ),
  $white;

//
// Overlays - são para sinalização de estados de interação
// Não usar com propriedade shorthand background, somente background-color.

$overlay-focused: 1px dashed #E21212;

$overlay-black-hover-color: rgba(0, 0, 0, 0.06);
$overlay-black-active-color: rgba(0, 0, 0, 0.15);
$overlay-black-disabled-color: #D2D2D2;

$overlay-white-hover-color: rgba(255, 255, 255, 0.08);
$overlay-white-active-color: rgba(255, 255, 255, 0.1);
$overlay-white-disabled-color: #D2D2D2;


// Content
// Color on Content over images
$image-content-color-high: $white;
$image-content-color-medium: $light-gray;
$image-content-color-disabled: #f3f3f3;
$image-content-color-detail: rgba(245, 135, 79, 1);

// Color on Content over surface
$surface-content-color-high: $black;
$surface-content-color-medium: $dark-gray;
$surface-content-color-disabled: #aeaeae;
$surface-content-color-detail: rgba($black, 0.1);
$surface-content-color-hover: #4D4D4D;

// Colors on Content over Profiles
// Colors on Main
$main-content-color-high: $main-dark;
$main-content-color-medium: $main;
$main-content-color-disabled: #eeeeee;
$main-content-color-detail: rgba($main, 0.1);
$main-content-color-hover: #EA8F61;

//Colors on Fito
$fito-content-color-high: $fito-dark;
$fito-content-color-medium: $fito;
$fito-content-color-disabled: #eeeeee;
$fito-content-color-detail: rgba($fito, 0.1);
$fito-content-color-hover: #367D4C;

//Colors on Spirit
$spirit-content-color-high: $spirit-darker;
$spirit-content-color-medium: $spirit-dark;
$spirit-content-color-disabled: #eeeeee;
$spirit-content-color-detail: rgba($spirit, 0.1);
$spirit-content-color-hover: #76378D;

//Colors on Prosper
$prosper-content-color-high: $prosper-dark;
$prosper-content-color-medium: $prosper-darker;
$prosper-content-color-disabled: #eeeeee;
$prosper-content-color-detail: rgba($prosper, 0.1);
$prosper-content-color-hover: #DF9046;

//
// Button Colors - text white em todos
$main-button-gradient: linear-gradient(180deg, rgba($white, 0.2) 0%, rgba($white, 0) 100%), $main-dark;
$fito-button-gradient: linear-gradient(180deg, rgba($white, 0.2) 0%, rgba($white, 0) 100%), $fito-dark;
$spirit-button-gradient: linear-gradient(180deg, rgba($white, 0.2) 0%, rgba($white, 0) 100%), $spirit-darker;
$prosper-button-gradient: linear-gradient(180deg, rgba($white, 0.2) 0%, rgba($white, 0) 100%), $prosper-dark;
$button-disabled: linear-gradient(180deg, rgba($white, 0.2) 0%, rgba($white, 0) 100%), #B9B9B9;
$button-text-color: $white;


$main-linear-gradient: linear-gradient(180deg, rgba($main-dark, 0.2)0%, rgba($main-dark, 0.2) 100%);
$fito-linear-gradient: linear-gradient(180deg, rgba($fito-dark, 0.2)0%, rgba($fito-dark, 0.2) 100%);
$spirit-linear-gradient: linear-gradient(180deg, rgba($spirit-dark, 0.2)0%, rgba($spirit-dark, 0.2) 100%);
$prosper-linear-gradient: linear-gradient(180deg, rgba($prosper-dark, 0.2)0%, rgba($prosper-dark, 0.2) 100%);
